<template>
  <div>
    <b-card>
      <b-input-group class="mb-2">
        <b-input-group-prepend>
          <b-button variant="primary" @click="list" :disabled="busy.list">
            <i class="fa fa-search"></i> 검색
            <b-spinner class="mr-1" small v-if="busy.list"></b-spinner>
          </b-button>
        </b-input-group-prepend>
        <b-form-input id="search" type="text" placeholder="" v-model="form.list.search" @keypress.enter="list()" autocomplete="off" v-focus></b-form-input>
      </b-input-group>

      <div class="grid">
        <div class="th text-center">No</div>
        <div class="th text-center">ID</div>
        <div class="th">이름</div>
        <div class="th text-center">참조수</div>
        <template v-for="e in items.page">
          <div class="td text-center">{{ e.no }}</div>
          <div class="td text-center">{{ e.id }}</div>
          <div class="td pointer bold" @click="loadScript(e)">{{ e.name }}</div>
          <div class="td text-center">
            <b-badge variant="light">{{ e.refCnt }}</b-badge>
          </div>
        </template>
      </div>

      <hr/>

      <div class="clearfix">
        <b-btn v-if="$R('DEV')" variant="primary" class="mr-1" @click="newScript()">새 Libs</b-btn>
        <b-btn variant="warning" class="mr-1" @click="reset">초기화</b-btn>
      </div>
    </b-card>

    <b-card>
      <div v-if="item.refCnt" class="mb-3">
        <small>참조중인 DataStore</small><br/>
        <a :href="`/#/data/store/${r.no}`" target="_blank" class="badge badge-primary pointer mr-1 mb-1" v-for="r in item.refs" :key="r.no">
          {{r.no}}. {{r.name}}
        </a>
      </div>
      <b-row class="mb-2">
        <b-col cols="12" md="1">
          <small>No</small>
          <b-input :value="item.no" readonly></b-input>
        </b-col>
        <b-col cols="12" md="2">
          <small>ID</small>
          <b-input v-model="item.id"></b-input>
        </b-col>
        <b-col cols="12" md="3">
          <small>이름</small>
          <b-input v-model="item.name"></b-input>
        </b-col>
        <b-col cols="12" md="6">
          <small>태그</small>
          <v-select v-model="item.tags" :options="options.tags" multiple taggable push-tags placeholder="상품, 주문 등의 TAG 를 입력해주세요">
            <template v-slot:no-options="{ search, searching }">
              <em style="opacity: 0.5;">관련 TAG 를 입력해주세요.</em>
            </template>
          </v-select>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12" md="12">
          <small>Libs 설명</small>
          <b-textarea rows="5" v-model="item.desc"></b-textarea>
        </b-col>
      </b-row>

      <div class="clearfix">
        <span class="pull-right">
          height:
          <b-badge v-for="h in ['300px', '600px', '800px', '80vh']" class="pointer" :variant="item.scriptHeight === h ? 'success' : 'light'"
                   @click="setHeight('script', h)" :key="h">{{h.replace('px', '').replace('vh', '%')}}</b-badge>
        </span>
        <small>스크립트 <i v-if="scriptError" class="fa fa-exclamation text-danger" v-b-tooltip="scriptError"></i></small>
      </div>
      <codemirror ref="script" v-model="item.script" @input="checkScriptEval('script')" @ready="e => e.setSize(null, item.scriptHeight)"></codemirror>

      <div class="my-2">
        <b-btn class="mr-1" variant="primary" @click="save" :disabled="busy.save">저장<b-spinner class="ml-1" small v-if="busy.save"></b-spinner></b-btn>
      </div>

      <div class="clearfix">
        <span class="pull-right">
          height:
          <b-badge v-for="h in ['100px', '300px', '600px', '800px', '80vh']" class="pointer" :variant="item.testScriptHeight === h ? 'success' : 'light'"
                   @click="setHeight('testScript', h)" :key="h">{{h.replace('px', '').replace('vh', '%')}}</b-badge>
        </span>
        <small>테스트 스크립트 <i v-if="testScriptError" class="fa fa-exclamation text-danger" v-b-tooltip="testScriptError"></i>
          - 지정된 함수들을 테스트할 때 사용됩니다. await 에 주의해주세요.
          sample :
          <i class="fa fa-copy pointer" :title="testScriptSample" @click="$utils.copyAlert(testScriptSample)" ></i>
        </small>
        <!-- ex) // item.params.push({name: 'test', type: 'string', desc: '', default: '', required: false}) -->
      </div>
      <codemirror ref="testScript" v-model="item.testScript" @input="checkScriptEval('testScript')" @ready="e => e.setSize(null, item.testScriptHeight)"></codemirror>

      <hr/>
      <b-btn class="mr-1" variant="success" @click="runTestScript" :disabled="busy.run">Test 실행<b-spinner class="ml-1" small v-if="busy.run"></b-spinner></b-btn>
      <b-btn class="mr-1" variant="primary" @click="save" :disabled="busy.save">저장<b-spinner class="ml-1" small v-if="busy.save"></b-spinner></b-btn>
      <b-btn class="mr-1" variant="danger" @click="remove" :disabled="busy.remove">삭제<b-spinner class="ml-1" small v-if="busy.remove"></b-spinner></b-btn>
    </b-card>
  </div>
</template>

<style scoped>
.grid {
  display: grid;
  grid-template-columns: 40px 1fr 2fr 1fr;
  margin: 10px 0;
}
.grid .th {
  padding: 5px;
  background-color: #eee;
}
.grid .td {
  padding: 5px;
}
span.highlight {
  font-weight: bold;
  background-color: #ffff7e;
}
</style>

<script>

export default {
  name: 'StoreLibs',

  data() {
    return {
      default: {
        item: {
          id: '',
          name: '',
          tags: [],
          desc: '',
          refs: [],
          refCnt: 0,
          script: '',
          scriptHeight: '600px',
          testScript: '',
          testScriptHeight: '100px',
        }
      },
      form: {
        list: {
          search: '',
        }
      },
      item: {

      },
      items: {
        list: [],
        page: [],
      },
      busy: {list: false, run: false, save: false, remove: false},
      scriptError: '',
      testScriptError: '',
      testScriptSample: `assert(1 + 2 === 3);\nassert((await fn()) === true);`,
      currentPage: 1,
      pageLimit: 10,

      options: {
        tags: []
      }
    }
  },
  created() {
    this.item = this.$utils.clone(this.default.item);
  },
  mounted() {
    this.list();
    this.loadTags();
  },
  sockets: {
    // 실시간 log 를 받아서 출력
    // 세션 및 txid 를 고려한다. 현재 창에만 띄운다.
    'console.log': (args) => {
      console.log(...args);
    },
    'console.pclog': (args) => {
      console.pclog(...args);
    },
    'console.error': (args) => {
      console.error(...args);
    },
  },
  watch: {
    form: {
      deep: true,
      handler() {
        this.filterList();
      },
    },
  },
  methods: {
    async list() {
      this.busy.list = true;
      const j = await this.$api.getJson(`/data/store/libs/list`);
      this.busy.list = false;
      if (j) {
        this.items.list = j.list;

        this.filterList();
        this.setPage(1);
      }
    },
    filterList() {
      const s = this.form.list.search.trim();
      let list;
      if (!s) {
        list = this.items.list;
      } else {
        const re = new RegExp(s.replace(/[/\\^$*+?.()|[\]{}]/g, '\\$&'), 'i'); // escape regexp
        list = this.items.list.filter(e => {
          return e.no === +s || re.test(e.name) || e.tags.some(t => re.test(t)) || (e.menus || []).some(t => re.test(t));
        });
      }
      this.items.list = list;
      this.setPage(this.currentPage);
    },
    async loadScript(e) {
      this.item = e;
      this.$refs.script.codemirror.setSize(null, this.item.scriptHeight);
      this.$refs.testScript.codemirror.setSize(null, this.item.testScriptHeight);
    },
    setPage(n) {
      this.currentPage = n;
      this.items.page = this.items.list.slice((n - 1) * this.pageLimit, n * this.pageLimit);
    },
    newScript() {
      this.item = this.$utils.clone(this.default.item);
      this.$refs.script.codemirror.setSize(null, this.item.scriptHeight);
      this.$refs.testScript.codemirror.setSize(null, this.item.testScriptHeight);
    },
    reset() {
      this.form.list.search = '';
      this.list();
    },
    async loadTags() {
      const j = await this.$api.getJson(`/data/store/libs/loadTags`);
      if (j) {
        this.options.tags = j.tags;
      }
    },

    setHeight(target, height) {
      this.$refs[target].codemirror.setSize(null, height);
      this.item[`${target}Height`] = height;
      this.$forceUpdate();
    },
    checkScriptEval(target) {
      try {
        eval(`(async function f() {${target === 'testScript' ? this.item.script + '\n' : ''}${this.item[target]}})`);
      } catch (e) {
        this[target + 'Error'] = e.toString();
        // console.error(e.stack);
        return false;
      }
      this[target + 'Error'] = '';
      return true;
    },
    async runTestScript() {
      const {no, script, testScript} = this.item;

      this.busy.run = true;
      const j = await this.$api.postJson('/data/store/libs/runTest', {no, script, testScript, ioId: this.$io.io.id});
      this.busy.run = false;
      if (j) {
        if (j.ok === -1) {
          this.$modal.show({title: '다음 에러를 확인해주세요: ' + j.error, html: j.msg.replace(/ /g,'&nbsp;').replace(/\n/g,'<br/>')
              .replace('<highlight>', '<span class="highlight">').replace('</highlight>', '</span>')});
        } else {
          this.$modal.show({title: 'return 된 결과를 확인해주세요', type: 'json', item: j.result});
        }
      }
    },
    async save() {
      if (!this.item.id) return alert(`ID 를 입력해주세요`);
      if (!this.item.name) return alert(`이름을 입력해주세요`);
      if (this.scriptError) return alert(`script 에러를 확인해주세요: ${this.scriptError}`);
      if (this.testScriptError) return alert(`testScript 에러를 확인해주세요: ${this.testScriptError}`);

      this.busy.save = true;
      const j = await this.$api.postJson('/data/store/libs/save', {item: this.item});
      this.busy.save = false;
      if (j) {
        this.item.no = j.no;
        this.$alertTop('저장되었습니다');
        this.$emit('list'); // this.list();
        this.$forceUpdate();
      }
      return true;
    },
    async remove() {
      if (!confirm('정말로 Libs 스크립트를 삭제하시겠습니까?')) return;
      this.busy.remove = true;
      const j = await this.$api.postJson('/data/store/libs/remove', {no: this.item.no});
      this.busy.remove = false;
      if (j) {
        this.$alertTop('삭제되었습니다', {variants: 'danger'});
        this.reset();
      }
    },
  }
}
</script>
